export default {
  EUR: 'i.iii €',
  USD: '$i,iii',
  MXN: '$i,iii',
  PEN: 'S/ i,iii',
  SAR: 'SAR i,iii',
  CAD: 'CA$ i,iii',
  CLP: '$ i.iii',
  COP: '$ i.iii',
  CZK: 'i.iii Kč',
  RUB: 'i.iii ₽',
  JPY: '¥i,iii',
  PLN: 'i,iii zł',
  ILS: 'i,iii ₪'
} as Record<string, string>;
