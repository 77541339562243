/*
  Export the messages in this file to be auto-loaded.

  A normal re-export like `export { default as en } from './en.messages'`,
  will make the `en` to be loaded immediately and included in the main chunk.

  If you instead prefer the messages to be loaded only when they are needed, export instead
  a function that imports the messages. This way, messages for a specific locale would be loaded
  when the active locale matches them.
 */

// Example of how to make the english language be loaded immediately
// export { default as en } from './en.messages.json';
// export { default as es } from './es.messages.json';

// Example of how to make the spanish messages be lazy loaded, only when the locale is set to `es`
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const es = () => import('./es.messages.json');
export const en = () => import('./en.messages.json');
export const fr = () => import('./fr.messages.json');
export const pt = () => import('./pt.messages.json');
export const ar = () => import('./ar.messages.json');
export const ca = () => import('./ca.messages.json');
export const cs = () => import('./cs.messages.json');
export const de = () => import('./de.messages.json');
export const el = () => import('./el.messages.json');
export const he = () => import('./he.messages.json');
export const it = () => import('./it.messages.json');
export const jp = () => import('./jp.messages.json');
export const pl = () => import('./pl.messages.json');
export const ru = () => import('./ru.messages.json');
export const sk = () => import('./sk.messages.json');
/* eslint-enable @typescript-eslint/explicit-function-return-type */
