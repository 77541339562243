import { namespacedDebounce } from '@empathyco/x-components';
import { trackNoResultsQueryWithSemanticsWire } from '@empathyco/x-components/tagging';

const moduleDebounce = namespacedDebounce('tagging');
export const trackNoResultsQueryWithSemanticsWireDebounced = moduleDebounce(
  trackNoResultsQueryWithSemanticsWire,
  ({ state }) => state.config.queryTaggingDebounceMs,
  {
    cancelOn: 'QueryPreviewUnmounted'
  }
);
