import {
  platformAdapter,
  PlatformRecommendationsRequest,
  PlatformSemanticQueriesRequest,
  PlatformResult,
  recommendationsRequestSchema,
  resultSchema,
  semanticQueriesRequestSchema,
  PlatformFacetType,
  FacetConfig,
  getFacetConfig,
  PlatformFacet,
  facetSchema,
  popularSearchesRequestSchema,
  relatedTagsRequestSchema,
  nextQueriesRequestSchema
} from '@empathyco/x-adapter-platform';
import {
  NumberRangeFacet,
  RecommendationsRequest,
  Result,
  SemanticQueriesRequest
} from '@empathyco/x-types';
import { MapperContext } from '@empathyco/x-adapter';

export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends Omit<PlatformResult, '__prices'> {
  description: string;
  collection: string;
  brand: string;
  lowestPrice: string;
  __prices?: {
    current: {
      value: number;
    };
    previous?: {
      value: number;
    };
    future?: {
      value: number;
    };
  };
}

declare module '@empathyco/x-types' {
  export interface Result {
    collection: string;
    description: string;
    brand: string;
    lowestPrice: string;
  }
}

declare module '@empathyco/x-adapter-platform' {
  export interface PlatformFacet {
    label: string;
  }
}
resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  description: 'description',
  collection: 'collection',
  brand: 'brand',
  lowestPrice: 'lowestPrice',
  images: ({ __images }) => (Array.isArray(__images) ? __images.reverse() : [__images]),
  price: ({ __prices: rawPrices }) => {
    if (rawPrices) {
      return {
        value: rawPrices.current.value,
        originalValue: rawPrices.previous?.value ?? rawPrices.current.value,
        futureValue: rawPrices.future?.value ?? rawPrices.current.value,
        hasDiscount:
          rawPrices.current.value < (rawPrices.previous?.value ?? rawPrices.current.value)
      };
    }

    return undefined;
  }
});

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
});

semanticQueriesRequestSchema.$override<
  SemanticQueriesRequest,
  Partial<PlatformSemanticQueriesRequest>
>({
  extraParams: ({ extraParams }) => {
    return {
      extraParams,
      filter_ids: 'NOT_ALL_WORDS,NOT_PARTIAL'
    };
  }
});

const getFacetConfigWithEditable = (facetId: string, type: PlatformFacetType): FacetConfig => {
  if (facetId === 'price') {
    const salePriceConfig = getFacetConfig('editable-range');
    const getSalePriceFilterId = (context?: MapperContext): string | undefined =>
      (context?.requestParameters?.filter as string[])?.find(filter => filter.includes('price'));

    salePriceConfig.schema = {
      ...salePriceConfig.schema,
      id: ({ filter }, $context) => {
        const currentSalePriceFilterId = getSalePriceFilterId($context);

        return currentSalePriceFilterId ?? filter;
      },
      label: (_, $context) => getSalePriceFilterId($context)?.split(':')[1],
      range: ({ value }, $context) => {
        const currentSalePriceFilterId = getSalePriceFilterId($context);

        const stringRage = currentSalePriceFilterId?.split(':')[1] ?? (value as string);

        const min = Number(stringRage.split('-')[0]);
        const max = Number(stringRage.split('-')[1]);

        return {
          min: Number.isNaN(min) ? null : min,
          max: Number.isNaN(max) ? null : max
        };
      }
    };

    return salePriceConfig;
  }

  return getFacetConfig(type);
};

facetSchema.$override<PlatformFacet, Partial<NumberRangeFacet>>({
  label: 'label',
  modelName: ({ type, facet }) => getFacetConfigWithEditable(facet, type).modelName as any,
  filters: {
    $path: 'values',
    $subSchema: ({ type, facet }) => getFacetConfigWithEditable(facet, type).schema,
    $context: {
      test: 'test'
    }
  }
});

popularSearchesRequestSchema.$override({
  start: () => undefined,
  rows: () => undefined,
  extraParams: ({ extraParams }) => {
    return {
      store: extraParams?.store,
      lang: extraParams?.lang
    };
  }
});

relatedTagsRequestSchema.$override({
  start: () => undefined,
  rows: () => undefined,
  extraParams: ({ extraParams }) => {
    return {
      store: extraParams?.store,
      lang: extraParams?.lang
    };
  }
});

nextQueriesRequestSchema.$override({
  start: () => undefined,
  rows: () => undefined,
  extraParams: ({ extraParams }) => {
    return {
      store: extraParams?.store,
      lang: extraParams?.lang
    };
  }
});
